<template>
  <h1 :class="classMap[pageType] || classMap.default">
    {{ title }}
  </h1>
</template>

<script setup lang="ts">
defineProps({
  pageType: {
    type: String,
    default: 'default',
  },
  title: {
    type: String,
    required: true,
  },
});

const classMap: { [key: string]: string } = {
  page: 'text-center px-35 lg:px-[150px] text-40 leading-50 font-bold text-grey-400 mb-30 lg:mb-20',
  category: 'text-42 font-khand leading-46 md:text-50 md:leading-55 font-bold uppercase mb-15',
  default: 'text-42 lg:text-50 font-khand leading-46 lg:leading-55 font-bold text-black mb-15 lg:mb-20',
};
</script>
